import axios from "axios";

const save = (cancelToken: any, data: any, params: any) => {
    return axios.post<any>(`${process.env.REACT_APP_API_URL}/public/reservations`, data, {
        params,
        cancelToken: cancelToken
    });
}
const services = {
    save
}
export default services;
