import {Moment} from 'moment';

/**
 * Wyliczenie liczby nocy pomiędzy datami
 * jeżeli ten sam dzień zakładamy że 1
 *
 * @param dateFrom
 * @param dateTo
 */
export const getNumberOfNights = (dateFrom: Moment, dateTo: Moment): any => {
    const days = dateTo.diff(dateFrom, 'days');

    if (days < 1) {
        return 1;
    }

    return days;
};
