import React, {useState} from 'react';
import {Star, ChevronsDown, Loader, ChevronsRight, ArrowRight} from 'react-feather';
import {IApartment} from "../models/Apartment";
import {useTranslation} from "react-i18next";
import {ApartmentImage} from "./ApartmentImage";
import {Modal} from "react-bootstrap";
import {ApartmentDetailsModal} from "./ApartmentDetailsModal";
import {ApartmentDetailsModal2} from "./ApartmentDetailsModal2";

interface AvailableApartmentsProps {
    availableApartments: IApartment[];
    loadNextPage: () => void;
    bookApartment: (apartment: IApartment) => void;
    isLoading: boolean;
    showLoadMore: boolean;
    showImage: boolean;
    page: number;
}

export const AvailableApartments = (props: AvailableApartmentsProps) => {
    const {t} = useTranslation();
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [selectedApartment, setSelectedApartment] = useState<IApartment>({});

    const getApartmentInfo = (apartment: IApartment) => {
        return (
            <>
                <p>
                    <span
                        className="configuration-info-span">{t('apartmentDetails.building')}: {apartment.building?.name}</span>
                </p>
                <p>
                    <span
                        className="configuration-info-span">{t('apartmentDetails.numberOfGuests')}: {apartment.numberOfGuests}</span>
                </p>
                <p>
                    <span
                        className="configuration-info-span">{t('recreationAreaAccessOptions.' + apartment.recreationAreaAccess)}</span>
                </p>
            </>
        );
    }

    const bookApartment = (apartment: IApartment) => {
        props.bookApartment(apartment);
    }

    const bookApartmentFromModal = (apartment: IApartment) => {
        setShowDetailsModal(false);
        setSelectedApartment({});
        props.bookApartment(apartment);
    }

    const closeModal = () => {
        setShowDetailsModal(false);
        setSelectedApartment({})
    }

    const toggleModal = (apartment: IApartment) => {
        setSelectedApartment(apartment)
        setShowDetailsModal(true);
    }

    if (props.isLoading && props.page === 0) {
        return (
            <>
                <div className="container-fluid apartment-list">
                    <div className="row">
                        <div className="col-md-12 text-center mt-4">
                            <Loader className="feather feather-spin feather-xl"/>
                            <p className="lead mt-2">{t('global.loading')}</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>

            <div className="container-fluid container-lg apartment-list">
                <div className="row">
                    <div className="col-md-12">
                        {(props.availableApartments.length < 1) && (
                            <h3 className="text-center mt-4 mb-4">
                                {t('noAvailableApartments')}
                            </h3>
                        )}

                        {props.availableApartments.map((apartment, index) => (
                            <div key={apartment.id} className="card mt-4 mb-2 apartment-card">
                                {apartment.isRecommended && (
                                    <div className={'recommended-badge-wrapper ' + (props.showImage ? '' : 'to-right')}>
                                        <Star className="feather"/>
                                        <span className="ml-2">{t('apartmentDetails.recommended')}</span>
                                    </div>
                                )}
                                <div className="row">
                                    {props.showImage && (
                                        <div className="col-md-4 apartment-card-image">
                                            <ApartmentImage apartment={apartment}/>
                                        </div>
                                    )}
                                    <div className={props.showImage ? 'col-md-8' : 'col-md-12'}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className={props.showImage ? 'col-md-12' : 'col-md-8'}>
                                                    {(apartment.name !== null && apartment.name !== undefined) && (
                                                        <p className="card-title mb-3">{apartment.name.length > 100 ? apartment.name?.substring(0, 100) + "..." : apartment.name}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-lg-6">
                                                    <div className="content-wrapper pl-0">
                                                        <p className="section-title">{t('apartmentDetails.apartment')}: </p>
                                                        <hr/>
                                                        {getApartmentInfo(apartment)}

                                                        <button className="btn btn-primary btn-main-light btn-sm d-none d-lg-block mt-3"
                                                                onClick={() => toggleModal(apartment)}>
                                                            <span className="mr-1">{t('global.more')}</span>
                                                            <ChevronsDown className="feather ml-1" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-6">
                                                    <div className="price-wrapper h-100">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <p className="mb-0 text-center">
                                                                    {t('apartmentDetails.apartmentIsAvailableInSelectedDate')}
                                                                </p>
                                                                <p className="p-price mb-2 text-center">
                                                                    <span className="price">{apartment.totalPrice}</span> zł
                                                                </p>
                                                                <div className="buttons-wrapper d-flex">
                                                                    <button className="btn btn-primary btn-main-light btn-block"
                                                                            onClick={() => bookApartment(apartment)}>
                                                                        <span className="mr-1">
                                                                            {t('apartmentDetails.book')}
                                                                        </span>
                                                                        <ArrowRight className="feather" />
                                                                    </button>

                                                                    <button className="btn btn-primary btn-main-light btn-sm d-lg-none btn-block"
                                                                            onClick={() => toggleModal(apartment)}>
                                                                        <span className="mr-1">{t('global.more')}</span>
                                                                        <ChevronsDown className="feather ml-1" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 text-center mb-4 mt-2">
                        {props.showLoadMore && (
                            <button className="btn btn-primary btn-main-light btn-block" onClick={() => props.loadNextPage()}>
                                {!props.isLoading && (
                                    <>
                                        <ChevronsDown className="feather"/>
                                        <span className="ml-1">{t('global.loadMore')}</span>
                                    </>
                                )}
                                {props.isLoading && (
                                    <>
                                        <Loader className="feather feather-spin"/>
                                        <span className="ml-1">{t('global.loading')}</span>
                                    </>
                                )}
                            </button>
                        )}
                    </div>
                </div>
            </div>

            <Modal
                show={showDetailsModal}
                onHide={closeModal}
                size="xl"
                centered={true}
                className={'details-modal'}
            >
                {/*<Modal.Header closeButton>*/}
                {/*    <Modal.Title>{t('apartmentReservation')}</Modal.Title>*/}
                {/*</Modal.Header>*/}
                <Modal.Body className={'details-modal-body'}>
                    {/*<ApartmentDetailsModal apartment={selectedApartment} bookApartment={bookApartmentFromModal}/>*/}
                    <ApartmentDetailsModal2 apartment={selectedApartment} bookApartment={bookApartmentFromModal} closeModal={closeModal}/>
                </Modal.Body>
            </Modal>
        </>
    )
}
