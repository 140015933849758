import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import * as momentLib from 'moment';
import {extendMoment} from 'moment-range';
import {useTranslation} from "react-i18next";
import ValidationMessage from "./ValidationMessage";
import {registerLocale} from "react-datepicker";
import reservationService from '../services/reservation.service';
import {de, enUS, pl} from "date-fns/locale";
import {IApartment} from "../models/Apartment";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import useAxiosCancel from "../hooks/useAxiosCancel";
import {toast} from "react-toastify";
import {format} from "date-fns";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {Loader, Check, ArrowLeft} from "react-feather";
import {getNumberOfNights} from "../utils/reservation.utils";

registerLocale("en", enUS);
registerLocale("pl", pl);
registerLocale("de", de);

const moment = extendMoment(momentLib);

const schema = (minGuests: number, maxGuests: any) => yup.object().shape({
    // Termin rezerwacji
    dateFrom: yup.string().required('validation.required'),
    dateTo: yup.string().required('validation.required'),

    // Liczba osób
    numberOfGuests: yup.number()
        .required('validation.required')
        .min(minGuests, {
            msg: 'validation.minGuests',
            params: {
                min: minGuests
            }
        })
        .max(maxGuests, {
            msg: 'validation.maxGuests',
            params: {
                max: maxGuests
            }
        }),

    // Dane personalne
    firstName: yup.string().required('validation.required'),
    lastName: yup.string().required('validation.required'),

    // Dane do faktury
    wantInvoice: yup.boolean(),

    companyName: yup.string().when("wantInvoice", {
        is: true,
        then: yup.string().required('validation.required')
    }),

    nip: yup.string().when("wantInvoice", {
        is: true,
        then: yup.string()
            .required('validation.required')
            .min(10, 'validation.notValidNip')
            .max(10, 'validation.notValidNip')
            .matches(/^[0-9]+$/, 'validation.notValidNip')
    }),

    street: yup.string().when("wantInvoice", {
        is: true,
        then: yup.string().required('validation.required')
    }),

    city: yup.string().when("wantInvoice", {
        is: true,
        then: yup.string().required('validation.required')
    }),

    postalCode: yup.string().when("wantInvoice", {
        is: true,
        then: yup.string().required('validation.required')
    }),

    country: yup.string().when("wantInvoice", {
        is: true,
        then: yup.string()
            .required('validation.required')
            .matches(/^[a-zA-Z]{2,}$/, 'validation.enterValidCountry'),
    }),

    email: yup.string()
        .email('validation.email')
        .required('validation.required'),

    phone: yup.string()
        .required('validation.required')
        .max(9, 'validation.validPhoneNumber')
        .matches(/^[0-9]*$/, 'validation.validPhoneNumber'),

    sendEmail: yup.string().required('validation.required'),

    // language: yup.string().required('validation.required'),

    guestRemarks: yup.string().nullable(),
});

interface ApartmentReservationFormProps {
    dateFrom: any;
    dateTo: any;
    closeModal: () => void;
    numberOfGuests: number;
    selectedApartment?: IApartment;
}

const MySwal = withReactContent(Swal);
const dateFormat = "iii, dd MMM yyyy";

export const ApartmentReservationForm = (props: ApartmentReservationFormProps) => {
    const {t, i18n} = useTranslation();
    const axiosSource = useAxiosCancel();
    const {executeRecaptcha} = useGoogleReCaptcha();

    const {register, handleSubmit, watch, formState: {errors}, setValue} = useForm({
        resolver: yupResolver(schema(1, props.selectedApartment?.numberOfGuests)),
    });

    const [startDate] = useState(moment(props.dateFrom).toDate());
    const [endDate] = useState(moment(props.dateTo).toDate());
    const watchWantInvoice = watch('wantInvoice', false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        setValue('dateFrom', startDate, {shouldValidate: true});
        setValue('dateTo', endDate, {shouldValidate: true});
        setValue('numberOfGuests', props.selectedApartment?.numberOfGuests, {shouldValidate: true});
    }, []);

    // Create an event handler, so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            // Execute recaptcha not yet available
            return;
        }

        return await executeRecaptcha('yourAction');
    }, []);

    const onSubmit = async (data: any) => {
        const token = await handleReCaptchaVerify();

        const result = await MySwal.fire({
            icon: 'question',
            text: t('bookForm.areYouSureYouWantToBookApartment'),
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: t('bookForm.no'),
            confirmButtonText: t('bookForm.yes')
        });

        if (result.value) {
            const postData = {...data};

            // Data zgłoszenia
            postData.dateOfReceipt = moment();
            postData.dateFrom = moment(data.dateFrom);
            postData.dateTo = moment(data.dateTo);

            // Liczba nocy
            postData.numberOfNights = getNumberOfNights(postData.dateFrom, postData.dateTo);
            postData.cancelled = false;
            postData.numberOfGuests = Number(postData.numberOfGuests);
            postData.reservationType = 'WEBSITE_WIDGET';
            postData.price = props.selectedApartment?.totalPrice;
            postData.leftToPay = props.selectedApartment?.totalPrice;
            postData.apartmentId = props.selectedApartment?.id;
            postData.captchaToken = token;

            postData.language = i18n.language;

            setIsSaving(true);
            try {
                await reservationService.save(axiosSource.token, postData, {});
                setIsSaving(false);
                toast.success(t('bookForm.reservationSuccess'));
                await MySwal.fire({
                    icon: 'success',
                    title: t('bookForm.swalReservationSuccessTitle'),
                    text: t('bookForm.swalReservationSuccessTitleInfo')
                });
                closeModal();
            } catch (e) {
                setIsSaving(false);
                toast.error(t('bookForm.cantCreateReservation'));
            }
        }
    }

    const closeModal = () => {
        props.closeModal();
    }

    let getCurrentDateFnsLocale = () => {
        switch (i18n.language) {
            case 'pl': {
                return pl;
            }

            case 'en': {
                return enUS;
            }

            case 'de': {
                return de;
            }

            default: {
                return pl;
            }
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <p className="lead mb-1">
                {t('bookForm.selectedApartment')}: <strong>{props.selectedApartment?.name}</strong>
            </p>
            <p className="lead mb-1">
                {t('bookForm.reservationDate')}: <strong>{format(startDate, dateFormat, {locale: getCurrentDateFnsLocale()})} - {format(endDate, dateFormat, {locale: getCurrentDateFnsLocale()})}</strong>
            </p>
            <p className="lead">{t('bookForm.price')}: <strong>{props.selectedApartment?.totalPrice} zł</strong></p>

            <div className="form-group mb-3">
                <label htmlFor="numberOfGuests">{t('bookForm.numberOfGuests')} *</label>
                <input
                    id="numberOfGuests"
                    className={'form-control ' + (errors.numberOfGuests ? 'is-invalid' : '')}
                    type="number"
                    step={1}
                    placeholder={t('bookForm.numberOfGuests')}
                    autoComplete="numberOfGuests"
                    {...register("numberOfGuests")}
                />
                {errors.numberOfGuests && (
                    <ValidationMessage error={errors.numberOfGuests}/>
                )}
            </div>

            <div className="form-group mb-3">
                <label htmlFor="firstName">{t('bookForm.firstName')} *</label>
                <input
                    id="firstName"
                    className={'form-control ' + (errors.firstName ? 'is-invalid' : '')}
                    type="text"
                    placeholder={t('bookForm.firstName')}
                    autoComplete="firstName"
                    {...register("firstName")}
                />
                {errors.firstName && (
                    <ValidationMessage error={errors.firstName}/>
                )}
            </div>

            <div className="form-group mb-3">
                <label htmlFor="lastName">{t('bookForm.lastName')} *</label>
                <input
                    id="lastName"
                    className={'form-control ' + (errors.lastName ? 'is-invalid' : '')}
                    type="text"
                    placeholder={t('bookForm.lastName')}
                    autoComplete="lastName"
                    {...register("lastName")}
                />
                {errors.lastName && (
                    <ValidationMessage error={errors.lastName}/>
                )}
            </div>

            <div className="form-check mb-3">
                <input
                    id="wantInvoice"
                    className={'form-check-input ' + (errors.wantInvoice ? 'is-invalid' : '')}
                    type="checkbox"
                    placeholder={t('bookForm.wantInvoice')}
                    autoComplete="wantInvoice"
                    {...register("wantInvoice")}
                />
                <label className="form-check-label" htmlFor="wantInvoice">{t('bookForm.wantInvoice')}</label>
            </div>

            {watchWantInvoice && (
                <>
                    <div className="form-group mb-3">
                        <label htmlFor="companyName">{t('bookForm.companyName')} *</label>
                        <input
                            id="companyName"
                            className={'form-control ' + (errors.companyName ? 'is-invalid' : '')}
                            type="text"
                            placeholder={t('bookForm.companyName')}
                            autoComplete="companyName"
                            {...register("companyName")}
                        />
                        {errors.companyName && (
                            <ValidationMessage error={errors.companyName}/>
                        )}
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="nip">{t('bookForm.nip')} *</label>
                        <input
                            id="nip"
                            className={'form-control ' + (errors.nip ? 'is-invalid' : '')}
                            type="text"
                            placeholder={t('bookForm.nip')}
                            autoComplete="nip"
                            {...register("nip")}
                        />
                        {errors.nip && (
                            <ValidationMessage error={errors.nip}/>
                        )}
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="street">{t('bookForm.street')} *</label>
                        <input
                            id="street"
                            className={'form-control ' + (errors.street ? 'is-invalid' : '')}
                            type="text"
                            placeholder={t('bookForm.street')}
                            autoComplete="street"
                            {...register("street")}
                        />
                        {errors.street && (
                            <ValidationMessage error={errors.street}/>
                        )}
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="city">{t('bookForm.city')} *</label>
                        <input
                            id="city"
                            className={'form-control ' + (errors.city ? 'is-invalid' : '')}
                            type="text"
                            placeholder={t('bookForm.city')}
                            autoComplete="city"
                            {...register("city")}
                        />
                        {errors.city && (
                            <ValidationMessage error={errors.city}/>
                        )}
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="postalCode">{t('bookForm.postalCode')} *</label>
                        <input
                            id="postalCode"
                            className={'form-control ' + (errors.postalCode ? 'is-invalid' : '')}
                            type="text"
                            placeholder={t('bookForm.postalCode')}
                            autoComplete="postalCode"
                            {...register("postalCode")}
                        />
                        {errors.postalCode && (
                            <ValidationMessage error={errors.postalCode}/>
                        )}
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="country">{t('bookForm.country')} *</label>
                        <input
                            id="country"
                            className={'form-control ' + (errors.country ? 'is-invalid' : '')}
                            type="text"
                            placeholder={t('bookForm.country')}
                            autoComplete="country"
                            {...register("country")}
                        />
                        {errors.country && (
                            <ValidationMessage error={errors.country}/>
                        )}
                    </div>
                </>
            )}

            <div className="form-group mb-3">
                <label htmlFor="email">{t('bookForm.email')} *</label>
                <input
                    id="email"
                    className={'form-control ' + (errors.email ? 'is-invalid' : '')}
                    type="text"
                    placeholder={t('bookForm.email')}
                    autoComplete="email"
                    {...register("email")}
                />
                {errors.email && (
                    <ValidationMessage error={errors.email}/>
                )}
            </div>

            <div className="form-group mb-3">
                <label htmlFor="phone">{t('bookForm.phone')} *</label>
                <input
                    id="phone"
                    className={'form-control ' + (errors.phone ? 'is-invalid' : '')}
                    type="text"
                    placeholder={t('bookForm.phone')}
                    autoComplete="phone"
                    {...register("phone")}
                />
                {errors.phone && (
                    <ValidationMessage error={errors.phone}/>
                )}
            </div>

            <div className="form-check mb-3">
                <input
                    id="sendEmail"
                    className={'form-check-input ' + (errors.sendEmail ? 'is-invalid' : '')}
                    type="checkbox"
                    placeholder={t('bookForm.sendEmail')}
                    autoComplete="sendEmail"
                    {...register("sendEmail")}
                />
                <label className="form-check-label" htmlFor="sendEmail">{t('bookForm.sendEmail')}</label>
            </div>

            {/*<div className="form-group mb-3">*/}
            {/*    <label htmlFor="language">{t('bookForm.language')} *</label>*/}
            {/*    <select*/}
            {/*        id="language"*/}
            {/*        className={'form-control ' + (errors.language ? 'is-invalid' : '')}*/}
            {/*        placeholder={t('bookForm.language')}*/}
            {/*        autoComplete="language"*/}
            {/*        {...register("language")}*/}
            {/*    >*/}
            {/*        <option value="pl">Polski</option>*/}
            {/*        <option value="en">English</option>*/}
            {/*        <option value="de">Deutsch</option>*/}
            {/*    </select>*/}
            {/*    {errors.language && (*/}
            {/*        <ValidationMessage error={errors.language}/>*/}
            {/*    )}*/}
            {/*</div>*/}

            <div className="form-group mb-3">
                <label htmlFor="guestRemarks">{t('bookForm.guestRemarks')} ({t('bookForm.optional')})</label>
                <textarea
                    id="guestRemarks"
                    className={'form-control ' + (errors.guestRemarks ? 'is-invalid' : '')}
                    rows={5}
                    {...register("guestRemarks")}
                />
                {errors.guestRemarks && (
                    <ValidationMessage error={errors.guestRemarks}/>
                )}
            </div>

            <div className="row">
                <div className="col-md-12 d-flex justify-content-between">
                    <button className="btn btn-btn-secondary btn-sm"
                            type="button"
                            onClick={closeModal}>
                        <ArrowLeft className="feather"/>
                        <span className="ml-1">{t('bookForm.cancel')}</span>
                    </button>

                    <button className="btn btn-primary btn-main-light" type="submit">
                        {isSaving && (
                            <Loader className="feather feather-spin"/>
                        )}
                        {!isSaving && (
                            <Check className="feather"/>
                        )}
                        <span className="ml-1">{t('bookForm.save')}</span>
                    </button>
                </div>
            </div>
        </form>
    );
}