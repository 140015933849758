import {useEffect, useState} from 'react';
import axios, {CancelTokenSource} from "axios";

// Anulowanie zapytań http jeśli screen jest wyłączany
export default function useAxiosCancel() {
    const [axiosSource, setAxiosSource] = useState<CancelTokenSource>({} as CancelTokenSource);

    useEffect(() => {
        const axiosSource: CancelTokenSource = axios.CancelToken.source();
        setAxiosSource(axiosSource);

        return () => {
            axiosSource.cancel('REQUEST CANCELED');
        }
    }, []);

    return axiosSource;
}
