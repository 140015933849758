import React from 'react';
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Flag} from 'react-feather';
import { useTranslation } from 'react-i18next';


export const AppNavbar = () => {
    const { i18n } = useTranslation();

    const changeLang = async (lang: string) => {
        await i18n.changeLanguage(lang);
    }

    return (
        <Navbar bg="light">
            <div className="container-fluid container-lg">
                <Navbar.Brand href="/">
                    <img src={process.env.PUBLIC_URL + '/logo_2.svg'}
                         style={{width: 40, height: 40}}
                         alt="Resort Apartamenty Klifowa Rewal"/>
                    {/*{t('appName')}*/}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="main-navbar-nav"/>
                <Navbar.Collapse id="main-navbar-nav">
                    <Nav className="ml-auto">
                        <NavDropdown title={
                            <>
                                <Flag size={24}/>
                                <span className="ml-2">{i18n.language}</span>
                            </>
                        } id="lang-nav-dropdown" alignRight>
                            <NavDropdown.Item onClick={() => changeLang('pl')}>
                                <span>Polski</span>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => changeLang('en')}>
                                <span>English</span>
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => changeLang('de')}>
                                <span>Deutsch</span>
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
}
