import {add} from "date-fns";
import * as _ from 'lodash';

export const getDataFromQueryParams = () => {
    // Pobranie dat startowych z query params
    const params = new URLSearchParams(window.location.search) // id=123
    const from = params.get('from');
    const to = params.get('to');
    const propertyValue = params.get('propertyValue');
    const buildingIds = params.get('buildingIds');
    const recreationAreaAccess = params.get('recreationAreaAccess');

    let defaultStartDate;
    let defaultEndDate;

    if (_.isNil(from)) {
        defaultStartDate = add(new Date(), {days: 0});
    } else {
        defaultStartDate = add(Date.parse(from), {days: 0});
    }

    if (_.isNil(to)) {
        defaultEndDate = add(new Date(), {days: 2});
    } else {
        defaultEndDate = add(Date.parse(to), {days: 0});
    }

    let defaultPropertyValue = null;
    if (!_.isNil(propertyValue)) {
        defaultPropertyValue = propertyValue;
    }

    let defaultBuildingIds = null;
    if (!_.isNil(buildingIds)) {
        defaultBuildingIds = buildingIds;
    }

    let defaultRecreationAreaAccess = null;
    if (!_.isNil(recreationAreaAccess)) {
        defaultRecreationAreaAccess = recreationAreaAccess;
    }

    return {defaultStartDate, defaultEndDate, defaultPropertyValue, defaultBuildingIds, defaultRecreationAreaAccess};
}