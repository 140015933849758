import React from "react";
import SimpleImageSlider from "react-simple-image-slider";

const images = [{url: '/placeholder.jpg'},]

export const ApartmentImage = (props: any) => {
    const getImages = () => {
        return props.apartment.photos.length > 0 ? props.apartment.photos : images
    }

    return (
        <SimpleImageSlider
            width={'100%'}
            height={'100%'}
            images={getImages()}
            showBullets={false}
            showNavs={true}
            navMargin={0}
            navSize={40}
            navStyle={2}
            style={{position: 'relative'}}
        />
    );
}
