import axios from "axios";
import {IApartment} from "../models/Apartment";
import {IPage} from "../models/Page";

const query = (cancelToken: any, params: any) => {
    return axios.get<IPage<IApartment>>(`${process.env.REACT_APP_API_URL}/public/apartments/simple`, {
        params,
        cancelToken: cancelToken
    });
}

const services = {
    query
}
export default services;
