import React from "react";
import {useTranslation} from "react-i18next";

interface ValidationMessageProps {
    error: any;
}

export const ValidationMessage = (props: ValidationMessageProps) => {
    const {t} = useTranslation();

    if (typeof props.error.message === 'string') {
        return (
            <div className="invalid-feedback d-block">{t(props.error.message)}</div>
        )
    } else {
        return (
            <div className="invalid-feedback d-block">{t(props.error.message.msg, props.error.message.params)}</div>
        )
    }
}
export default ValidationMessage
