import React from "react";
import {IApartment, IPublicApartmentConfiguration} from "../models/Apartment";
import * as _ from "lodash";
import {useTranslation} from "react-i18next";
import SimpleImageSlider from "react-simple-image-slider";
import {ArrowLeft, ArrowRight, ChevronsDown, ChevronsRight, Loader, X} from "react-feather";
import ReactHtmlParser from 'react-html-parser';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const images = [{url: '/placeholder.jpg'},]

export const ApartmentDetailsModal2 = (props: any) => {
    const {t} = useTranslation();

    const getTitleImageUrl = () => {
        if (_.isNil(props.apartment.photos) || props.apartment.photos.length < 1) {
            return images[0].url;
        }

        return props.apartment.photos[0].url;
    }

    const getImages = () => {
        if (_.isNil(props.apartment.photos) || props.apartment.photos.length < 1) {
            return images
        }

        return props.apartment.photos.length > 0 ? props.apartment.photos : images
    }

    const hasImages = () => {
        if (_.isNil(props.apartment.photos) || props.apartment.photos.length < 1) {
            return false;
        }

        return props.apartment.photos.length > 0;
    }

    const bookApartment = (apartment: IApartment) => {
        props.bookApartment(apartment);
    }

    const getApartmentConfigurationsByCategoryId = (categoryId: number | null = null): IPublicApartmentConfiguration[] => {
        if (categoryId === null) {
            return props.apartment.publicApartmentConfiguration;
        }
        return props.apartment.publicApartmentConfiguration?.filter((c: IPublicApartmentConfiguration) => c.categoryId === categoryId);
    }

    const getBaseInfo = () => {
        const confs = getApartmentConfigurationsByCategoryId(1);
        return (
            <>
                {confs.map((c, index) => {
                    return (
                        <div className="col-md-3 info-table-item">
                            <p key={index} className="mb-0">
                                <span
                                    className="configuration-info-span">{c.label}{getConfigurationValue(c.value)}</span>
                            </p>
                        </div>
                    );
                })}
            </>
        );
    }

    const getEquipmentInfo = () => {
        const confs = getApartmentConfigurationsByCategoryId(2);
        return (
            <>
                <div className="col-md-12">
                    <p className="lead">Najpopularniejsze udogodnienia</p>
                </div>
                {confs.map((c, index) => {
                    return (
                        <div className="col-md-3 eq-info-table-item-wrapper">
                            <div className="eq-info-table-item">
                                <p key={index} className="mb-0">
                                <span className="configuration-info-span">{c.value === 'NIE' ?
                                    <FontAwesomeIcon icon={["fas", "xmark"]} className="font-awesome mr-2"/> :
                                    <FontAwesomeIcon icon={["fas", "check"]} className="font-awesome mr-2"/>}

                                    {c.label}
                                </span>
                                </p>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    }

    const getAllInfo = () => {
        const confs = getApartmentConfigurationsByCategoryId(null);
        if (confs == null) {
            return null;
        }
        return (
            <>
                <div className="col-md-12">
                    <p className="lead">Najpopularniejsze udogodnienia</p>
                </div>
                {confs.map((c, index) => {
                    return (
                        <div className="col-md-4 eq-info-table-item-wrapper">
                            <div className="eq-info-table-item">
                                <p key={index} className="mb-0">
                                <span className="configuration-info-span">
                                    {getIconByValue(c.value)}
                                    {c.label}
                                    {getValueToDisplay(c.value)}
                                </span>
                                </p>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    }

    const getIconByValue = (value: any) => {
        if (value === 'NIE') {
            return (<FontAwesomeIcon icon={["fas", "xmark"]} className="font-awesome mr-2"/>);
        } else {
            return (<FontAwesomeIcon icon={["fas", "check"]} className="font-awesome mr-2"/>);
        }
    }

    const getValueToDisplay = (value: any) => {
        if (value === 'TAK' || value === 'NIE') {
            return '';
        } else {
            return ': ' + value;
        }
    }

    const getConfigurationValueForAllInfo = (value: any) => {
        if (value === 'NIE') {
            return (<FontAwesomeIcon icon={["fas", "xmark"]} className="font-awesome ml-1"/>);
        } else if (value === 'TAK') {
            return (<FontAwesomeIcon icon={["fas", "check"]} className="font-awesome ml-1"/>);
        } else {
            return ': ' + value;
        }
    }

    const getConfigurationValue = (value: any) => {
        if (value === 'NIE') {
            return (<FontAwesomeIcon icon={["fas", "xmark"]} className="font-awesome ml-1"/>);
        } else if (value === 'TAK') {
            return (<FontAwesomeIcon icon={["fas", "check"]} className="font-awesome ml-1"/>);
        } else
            return ': ' + value;
    }

    const closeModal = () => {
        props.closeModal();
    }

    return (
        <div className="details-2">
            <div className="title-image" style={{backgroundImage: `url("${getTitleImageUrl()}")`}}>
                <div className="row title-content-wrapper h-100">
                    <div className="col-md-6 d-flex justify-content-center flex-column">
                        <h1 className="apartment-name">{props.apartment.name}</h1>
                        <p className="description">
                            {ReactHtmlParser(props.apartment.description)}
                        </p>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                        <div className="book-wrapper">
                            <p>{t('apartmentDetails.apartmentIsAvailableInSelectedDate')}</p>
                            <p className="p-price">
                                <span className="price">{props.apartment.totalPrice}</span> zł
                            </p>

                            <button className="btn btn-primary btn-main-light btn-block book-btn"
                                    onClick={() => bookApartment(props.apartment)}>
                                <span
                                    className="mr-1">{t('apartmentDetails.book')}</span>
                                <ArrowRight className="feather"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                {/*<div className="row info-table-wrapper">*/}
                {/*    {getBaseInfo()}*/}
                {/*</div>*/}

                <div className="row eq-info-table-wrapper">
                    {/*{getEquipmentInfo()}*/}
                    {getAllInfo()}
                </div>

                {hasImages() && (
                    <div className="row">
                        <div className="col-md-12 slider-wrapper">
                            <div className="slider-wrapper-inner">
                                <SimpleImageSlider
                                    width={'100%'}
                                    height={'100%'}
                                    images={getImages()}
                                    showBullets={false}
                                    showNavs={true}
                                    navMargin={0}
                                    navSize={40}
                                    navStyle={2}
                                    style={{position: 'relative'}}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className="row">
                    <div className="col-md-12">
                        <div className="book-bottom-wrapper" style={{backgroundImage: `url("banner-3.jpg")`}}>
                            <div className="content d-flex justify-content-center">
                                <div className="text-center">
                                    <p className="lead">{t('apartmentDetails.interested')}</p>
                                    <p className="lead-2">{t('apartmentDetails.bookNow')}</p>

                                    <p className="p-price">
                                        <span className="price">{props.apartment.totalPrice}</span> zł
                                    </p>

                                    <button className="btn btn-primary btn-main-light book-btn btn-block"
                                            onClick={() => bookApartment(props.apartment)}>
                                        <span className="mr-1">
                                            {t('apartmentDetails.book')}
                                        </span>
                                        <ArrowRight className="feather"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-4 mt-4">
                    <div className="col-md-12 text-center">
                        <button className="btn btn-btn-secondary btn-sm"
                                type="button"
                                onClick={closeModal}>
                            <X className="feather"/>
                            <span className="ml-1">{t('global.close')}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
