import React, {useEffect, useState} from 'react';
import {AppNavbar} from "./components/AppNavbar";
import {DateRangeSelector} from "./components/DateRangeSelector";

import ApartmentService from "../src/services/apartment.service";

// import i18n (needs to be bundled ;))
import './i18n';
import {AvailableApartments} from "./components/AvailableApartments";
import {IApartment} from "./models/Apartment";
import {AxiosResponse} from "axios";
import useAxiosCancel from "./hooks/useAxiosCancel";
import {IPage} from "./models/Page";
import {format} from "date-fns";
import {toast, ToastContainer} from "react-toastify";
import {useTranslation} from "react-i18next";
import {Modal} from "react-bootstrap";
import {ApartmentReservationForm} from "./components/ApartmentReservationForm";
import {AppFooter} from "./components/AppFooter";
import {getDataFromQueryParams} from "./utils/dates.utils";
import { library } from '@fortawesome/fontawesome-svg-core';
import {faPersonSwimming, faCalendarDays, faXmark, faCheck} from '@fortawesome/free-solid-svg-icons';

library.add(faPersonSwimming, faCalendarDays, faXmark, faCheck);

const dataFromQueryParams = getDataFromQueryParams();

function App() {
    const {t} = useTranslation();
    const axiosSource = useAxiosCancel();

    const [availableApartments, setAvailableApartments] = useState<IApartment[]>([]);
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(false);
    const [showBookModal, setShowBookModal] = useState(false);
    const [selectedApartment, setSelectedApartment] = useState<IApartment>({});

    const [searchData, setSearchData] = useState({
        numberOfGuests: '',
        numberOfApartments: 1,
        dateFrom: format(dataFromQueryParams.defaultStartDate, 'yyyy-MM-dd'),
        dateTo: format(dataFromQueryParams.defaultEndDate, 'yyyy-MM-dd'),
        propertyId: 6,
        propertyValue: dataFromQueryParams.defaultPropertyValue,
        buildingIds: dataFromQueryParams.defaultBuildingIds,
        recreationAreaAccess: dataFromQueryParams.defaultRecreationAreaAccess
    });

    const getSearchData = () => {
        return {
            // numberOfGuests: searchData.numberOfGuests,
            dateFrom: searchData.dateFrom,
            dateTo: searchData.dateTo,
            propertyId: searchData.propertyId,
            propertyValue: searchData.propertyValue,
            buildingIds: searchData.buildingIds,
            recreationAreaAccess: searchData.recreationAreaAccess
        }
    }

    const getApartments = async (clear: boolean = false) => {
        setIsLoading(true);

        try {
            const res: AxiosResponse<IPage<IApartment>> = await ApartmentService.query(axiosSource.token, {
                size: 10,
                page: page,
                sort: 'reservationsCount,asc',
                ...getSearchData()
            });

            setAvailableApartments(state => {
                if (clear) {
                    return [...res.data.content];
                } else {
                    return [...state, ...res.data.content];
                }
            });

            if (res.data.last) {
                setShowLoadMoreBtn(false);
            } else {
                setShowLoadMoreBtn(true);
            }
        } catch (error) {
            toast.error(t('cantGetApartments'));
        }

        setIsLoading(false);
    }

    useEffect(() => {
        getApartments();
    }, [page, searchData]);

    const onLoadNextPage = () => {
        setPage(page + 1);
    }

    const onSearchDataSet = (data: any) => {
        setAvailableApartments([]);
        setSearchData({
            ...searchData,
            ...data
        });
        setPage(0);
    }

    const onBookApartment = (apartment: IApartment) => {
        setSelectedApartment(apartment);
        setShowBookModal(state => !state);
    }

    const closeModal = () => {
        setShowBookModal(false);
    }

    return (
        <>
            <div id="page-content">
                <AppNavbar/>
                <DateRangeSelector searchDataSet={onSearchDataSet}/>
                <AvailableApartments
                    availableApartments={availableApartments}
                    loadNextPage={onLoadNextPage}
                    isLoading={isLoading}
                    showLoadMore={showLoadMoreBtn}
                    bookApartment={onBookApartment}
                    showImage={true}
                    page={page}
                />
            </div>

            <AppFooter/>

            <ToastContainer
                theme={'dark'}
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <Modal
                show={showBookModal}
                onHide={closeModal}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('apartmentReservation')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ApartmentReservationForm
                        dateFrom={searchData.dateFrom}
                        dateTo={searchData.dateTo}
                        numberOfGuests={Number(searchData.numberOfGuests?.split(',')[0])}
                        selectedApartment={selectedApartment}
                        closeModal={closeModal}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default App;
