import React, {useState} from 'react';
import DatePicker, {registerLocale} from "react-datepicker";
import {Calendar, ChevronRight, PlusCircle, MinusCircle, XCircle, CheckCircle, Home, CheckSquare, Square} from 'react-feather';
import {useTranslation} from "react-i18next";
import {add, format} from "date-fns";

import {enUS, pl, de} from "date-fns/locale";
import {getDataFromQueryParams} from "../utils/dates.utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

registerLocale("en", enUS);
registerLocale("pl", pl);
registerLocale("de", de);

const dateFormat = "iii, dd MMM yyyy";
const minDate = new Date();
const maxDate = add(new Date(), {years: 1});
const dataFromQueryParams = getDataFromQueryParams();
const minNumberOfPersonsInApartment = 1;
const maxNumberOfPersonsInApartment = 20;
const singleApartmentMode = true;

interface DateRangeSelectorProps {
    searchDataSet: (data: any) => void;
}

const recreationAreaAccessOptions: any[] = [
    {label: 'NO_ACCESS_TO_RECREATION_AREA', value: 'NO_ACCESS_TO_RECREATION_AREA'},
    {label: 'ACCESS_TO_RECREATION_AREA', value: 'ACCESS_TO_RECREATION_AREA'},
    {label: 'IN_BUILDING_WITH_ACCESS_TO_RECREATION_AREA', value: 'IN_BUILDING_WITH_ACCESS_TO_RECREATION_AREA'},
    {label: 'NO_PREFERENCES', value: null},
];

export const DateRangeSelector = (props: DateRangeSelectorProps) => {
    const {t, i18n} = useTranslation();

    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [isApartmentPickerOpen, setIsApartmentPickerOpen] = useState(false);
    const [isRecreationAreaAccessPickerOpen, setIsRecreationAreaAccessPickerOpen] = useState(false);

    const [startDate, setStartDate] = useState(dataFromQueryParams.defaultStartDate);
    const [endDate, setEndDate] = useState(dataFromQueryParams.defaultEndDate);
    const [neededApartments, setNeededApartments] = useState(
        [{persons: 2}]
    );

    const [selectedRecreationAreaAccess, setSelectedRecreationAreaAccess] = useState(dataFromQueryParams.defaultRecreationAreaAccess);

    const onChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const cancel = () => {
        setStartDate(dataFromQueryParams.defaultStartDate);
        setEndDate(dataFromQueryParams.defaultEndDate);
        setIsPickerOpen(false);
    }

    const togglePicker = () => {
        if (isPickerOpen) {
            setIsPickerOpen(false);
        } else {
            setIsPickerOpen(true);
        }
        setIsApartmentPickerOpen(false);
    }

    const toggleApartmentPicker = () => {
        if (isApartmentPickerOpen) {
            setIsApartmentPickerOpen(false);
        } else {
            setIsApartmentPickerOpen(true);
        }
        setIsPickerOpen(false);
    }

    const toggleRecreationAreaAccessPicker = () => {
        if (isRecreationAreaAccessPickerOpen) {
            setIsRecreationAreaAccessPickerOpen(false);
        } else {
            setIsRecreationAreaAccessPickerOpen(true);
        }
        setIsPickerOpen(false);
    }

    const rangeSet = () => {
        setIsPickerOpen(false);
        props.searchDataSet(getSearchData());
    }

    const neededApartmentsSet = () => {
        setIsApartmentPickerOpen(false);
        props.searchDataSet(getSearchData());
    }

    const recreationAreaAccessSet = () => {
        setIsRecreationAreaAccessPickerOpen(false);
        props.searchDataSet(getSearchData());
    }

    const getSearchData = (): any => {
        return {
            // numberOfGuests: neededApartments.map(a => a.persons).join(','),
            // numberOfApartments: neededApartments.length,
            dateFrom: format(startDate, 'yyyy-MM-dd'),
            dateTo: format(endDate, 'yyyy-MM-dd'),
            recreationAreaAccess: selectedRecreationAreaAccess
        }
    }

    const getTotalPersons = () => {
        let total = 0;
        neededApartments.map(a => total += a.persons);
        return total;
    }

    let getCurrentDateFnsLocale = () => {
        switch (i18n.language) {
            case 'pl': {
                return pl;
            }

            case 'en': {
                return enUS;
            }

            case 'de': {
                return de;
            }

            default: {
                return pl;
            }
        }
    };

    const increment = (index: any) => {
        setNeededApartments(state => {
            const current = {...state[index]};
            current.persons++;
            if (current.persons > maxNumberOfPersonsInApartment) {
                current.persons = maxNumberOfPersonsInApartment;
            }
            const newArray = [...state];
            newArray[index] = current;
            return newArray;
        });
    }

    const decrement = (index: any) => {
        setNeededApartments(state => {
            const current = {...state[index]};
            current.persons--;
            if (current.persons < minNumberOfPersonsInApartment) {
                current.persons = minNumberOfPersonsInApartment;
            }
            const newArray = [...state];
            newArray[index] = current;
            return newArray;
        });
    }

    const addNextApartment = () => {
        setNeededApartments(state => {
            const newArray = [...state];
            newArray.push({
                persons: minNumberOfPersonsInApartment
            });
            return newArray;
        })
    }

    const removeApartment = (index: any) => {
        setNeededApartments(state => {
            const newArray = [...state];
            newArray.splice(index, 1);
            return newArray;
        })
    }

    return (
        <>
            <div className="container-fluid date-range-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12 date-range-wrapper">
                                <div className="date-range-picker-wrapper">
                                    <div className="item-wrapper from mr-1" onClick={togglePicker}>
                                        <span className="item-icon">
                                            {/* <Calendar className="feather"/> */}
                                            <FontAwesomeIcon icon={["fas", "calendar-days"]} className="font-awesome"/>
                                            </span>
                                        <span className="item-text">
                                        {format(startDate, dateFormat, {locale: getCurrentDateFnsLocale()})}
                                    </span>
                                    </div>
                                    <div className="connector-icon">
                                        <ChevronRight className="feather"/>
                                    </div>
                                    <div className="item-wrapper to ml-1" onClick={togglePicker}>
                                    <span className="item-text">
                                        {endDate !== null && (
                                            format(endDate, dateFormat, {locale: getCurrentDateFnsLocale()})
                                        )}
                                        {endDate === null && (
                                            '-'
                                        )}
                                    </span>
                                    </div>
                                </div>
                                {/*<div className="item-wrapper number-of-people ml-4" onClick={toggleApartmentPicker}>*/}
                                {/*    <span className="item-icon"><Users className="feather"/></span>*/}
                                {/*    <span className="item-text">*/}
                                {/*        {singleApartmentMode && (*/}
                                {/*            <>{t('persons')}: {getTotalPersons()}</>*/}
                                {/*        )}*/}
                                {/*        {!singleApartmentMode && (*/}
                                {/*            <>{t('persons')}: {getTotalPersons()}, {t('apartments')}: {neededApartments.length}</>*/}
                                {/*        )}*/}
                                {/*    </span>*/}
                                {/*</div>*/}
                                <div className="item-wrapper number-of-people ml-4" onClick={toggleRecreationAreaAccessPicker}>
                                    <span className="item-icon">
                                        {/* <Home className="feather"/> */}
                                        <FontAwesomeIcon icon={["fas", "person-swimming"]} className="font-awesome"/>
                                        </span>
                                    <span className="item-text">
                                        {t('recreationArea')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isPickerOpen && (
                <div className="position-absolute d-flex justify-content-center w-100" style={{zIndex: 9}}>
                    <div className="picker-box-container">
                        <div className="col-md-12 text-center pl-0 pr-0">
                            <DatePicker
                                selected={startDate}
                                onChange={onChange}
                                monthsShown={2}
                                selectsRange
                                startDate={startDate}
                                endDate={endDate}
                                inline
                                locale={i18n.language}
                                minDate={minDate}
                                maxDate={maxDate}
                            />
                        </div>
                        <div className="col-md-12 d-flex justify-content-end mt-2">
                            <button className="btn btn-primary btn-main-light btn-sm increment d-flex align-items-center mr-2"
                                    onClick={cancel}>
                                <XCircle className="feather"/> <span className="ml-2">{t('global.cancel')}</span>
                            </button>

                            <button className="btn btn-primary btn-main-light btn-sm increment d-flex align-items-center"
                                    onClick={rangeSet}>
                                <CheckCircle className="feather"/> <span className="ml-2">{t('global.save')}</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isApartmentPickerOpen && (
                <div className="position-absolute d-flex justify-content-center w-100" style={{zIndex: 9}}>
                    <div className="picker-box-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="needed-apartments-container">
                                    {neededApartments.map((a, index) => (
                                        <div key={index} className="card mb-2" style={{minWidth: 300}}>
                                            <div
                                                className="card-header d-flex align-items-center justify-content-between">
                                                {singleApartmentMode && (
                                                    <strong>{t('apartment')}</strong>
                                                )}
                                                {!singleApartmentMode && (
                                                    <strong>{t('apartment')}: {index + 1}</strong>
                                                )}

                                                {(index > 0 && !singleApartmentMode) && (
                                                    <button className="btn btn-link remove-apartment"
                                                            onClick={() => removeApartment(index)}>
                                                        <XCircle className="feather"/>
                                                    </button>
                                                )}
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6 d-flex align-items-center">
                                                        {t('persons')}: <span
                                                        className="ml-1"><strong>{a.persons}</strong></span>
                                                    </div>
                                                    <div className="col-md-6 text-right">
                                                        <button className="btn btn-link increment"
                                                                onClick={() => increment(index)}>
                                                            <PlusCircle className="feather"/>
                                                        </button>
                                                        <button className="btn btn-link decrement ml-2"
                                                                onClick={() => decrement(index)}>
                                                            <MinusCircle className="feather"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-12 d-flex justify-content-between">
                                {!singleApartmentMode && (
                                    <button
                                        className="btn btn-primary btn-main-light btn-sm increment d-flex align-items-center"
                                        onClick={() => addNextApartment()}>
                                        <PlusCircle className="feather"/> <span
                                        className="ml-2">{t('addNextApartment')}</span>
                                    </button>
                                )}

                                <button className="btn btn-primary btn-main-light btn-sm increment d-flex align-items-center"
                                        onClick={() => neededApartmentsSet()}>
                                    <CheckCircle className="feather"/> <span className="ml-2">{t('global.save')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {isRecreationAreaAccessPickerOpen && (
                <div className="position-absolute d-flex justify-content-center w-100" style={{zIndex: 9}}>
                    <div className="picker-box-container">
                        <div className="d-flex flex-column">
                            {recreationAreaAccessOptions.map(option => (
                                <div key={option.label} className="d-inline-flex mb-2 clickable" onClick={() => setSelectedRecreationAreaAccess(option.value)}>
                                    <div>
                                        {selectedRecreationAreaAccess === option.value ? <CheckSquare></CheckSquare> : <Square></Square>}
                                    </div>
                                    <span className="ml-2">{t('recreationAreaAccessOptions.' + option.label)}</span>
                                </div>
                            ))}
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-12 d-flex justify-content-between">
                                <button className="btn btn-primary btn-main-light btn-sm increment d-flex align-items-center"
                                        onClick={() => recreationAreaAccessSet()}>
                                    <CheckCircle className="feather"/> <span className="ml-2">{t('global.save')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
